import React, { Component } from 'react';
import LineChart from './LineChart';
import './index.css';
import './Chart.css';

class Charts extends Component {
    render() { 
        return (
            <div className='charts' id='charts'>
                {this.props.charts.map(chart => (
                    <LineChart 
                        key={chart.id}
                        options={chart.options}
                    /> 
                ))}
            </div>
        );
    }
}
 
export default Charts;