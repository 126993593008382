import React, { Component } from 'react';
import Highcharts from 'highcharts/highstock';
import Exporting from 'highcharts/modules/exporting';
import HighchartsReact from 'highcharts-react-official';

Exporting(Highcharts);
class LineChart extends Component {      
    render() { 
        return (
            <HighchartsReact
                highcharts={Highcharts}
                options={this.props.options}
                constructorType={'stockChart'}
                containerProps = {{ className: 'chart' }}
            />
        );
    }
}
 
export default LineChart;