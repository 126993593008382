import React, { Component } from 'react';
import './CurrentWeather.css';

class CurrentWeather extends Component {
  createTendency = () => {
    const currentTemp = Math.round(this.props.currentData.temperature.celsius);
    const previousTemp = this.props.currentData.temperature.previousTemp;
    if (currentTemp > previousTemp) {
      return '/images/icons/up.svg';
    } else if (currentTemp < previousTemp) {
      return '/images/icons/down.svg';
    }

    return '/images/icons/normal.svg';
  };

  render() {
    if (this.props.error)
      return (
        <div className='currentWeather error'>
          <h1>¡Algo salió mal al obtener el clima actual!</h1>
          <p>Parece que nuestra API interna no está funcionando actualmente.</p>
        </div>
      );

    return (
      <div className='currentWeather'>
        <div className='dateAndTime'>
          <div className='date'>
            {this.props.currentData.lastUpdate.toFormat(
              "EEEE',' dd 'de' MMMM HH':'mm' hrs'"
            )}
          </div>
          <div className='location'>Ciudad de México</div>
        </div>
        <div
          className='temperature'
          onClick={() => this.props.handleConvertUnits()}
        >
          <div className='digits'>
            {this.props.currentData.temperature.currentUnit === 'celsius'
              ? this.props.currentData.temperature.celsius
              : this.props.currentData.temperature.fahrenheit}
          </div>
          <div className='right'>
            <div className='units'>
              °
              {this.props.currentData.temperature.currentUnit === 'celsius'
                ? 'C'
                : 'F'}
            </div>
            <img
              className='tendency'
              alt='Tendency icon'
              src={this.createTendency()}
            ></img>
          </div>
        </div>
        <div className='otherStats'>
          <div className='stat'>
            <img src='/images/icons/humidity.svg' alt='Humidity icon'></img>
            {this.props.currentData.humidity}%
          </div>
          <div className='stat'>
            <img src='/images/icons/pressure.svg' alt='Presure icon'></img>
            {this.props.currentData.pressure}hPa
          </div>
          <div className='stat'>
            <img src='/images/icons/altitude.svg' alt='Altitude icon'></img>
            {this.props.currentData.altitude}m
          </div>
        </div>
        <a href='#charts'>
          <img
            className='down-arrow'
            src='/images/icons/angle-down.svg'
            alt='Down arrow'
          ></img>
        </a>
      </div>
    );
  }
}

export default CurrentWeather;
