import React, { Component } from 'react';
import { DateTime } from 'luxon';
import './App.css';
import CurrentWeather from './components/CurrentWeather';
import Charts from './components/Charts';
import Loader from './components/Loader';

class App extends Component {
  state = {
    current: {
      lastUpdate: DateTime.now().setZone('America/Mexico_City').setLocale('es'),
      temperature: {
        currentUnit: 'celsius',
        previousTemp: 0,
        celsius: 0,
        fahrenheit: 0,
      },
      humidity: 0,
      pressure: 0,
      altitude: 0,
    },
    charts: [],
    loader: true,
    error: false,
  };

  componentDidMount() {
    this.handleUpdate();
    setInterval(this.handleUpdate, 60000);

    // Handle creation of charts
    fetch('https://api.clima.xe1e.net/ultimos?cantidad=400')
      .then((response) => response.json())
      .then((data) => {
        if (!data.exito) return;
        const charts = [];
        for (let i = 0; i < data.ultimos.length; i++) {
          const current = data.ultimos[i];
          charts.push({
            id: i,
            options: {
              title: {
                text: `${current.nombre}`,
              },
              subtitle: {
                text: 'Últimos 400 datos',
              },
              rangeSelector: {
                buttons: [
                  {
                    count: 1,
                    type: 'week',
                    text: '1s',
                    events: {
                      click: () => {
                        console.log('Achu');
                      },
                    },
                  },
                  {
                    count: 1,
                    type: 'day',
                    text: '1d',
                  },
                  {
                    count: 12,
                    type: 'hour',
                    text: '12h',
                  },
                  {
                    type: 'all',
                    text: 'Todo',
                  },
                ],
                inputEnabled: true,
                selected: 3,
              },
              time: {
                timezoneOffset: new Date().getTimezoneOffset(),
              },
              tooltip: {
                valueSuffix: current.unidad,
              },
              series: [
                {
                  name: `${current.nombre}`,
                  data: current.datos,
                },
              ],
            },
          });
        }

        this.setState({
          charts,
        });
      });
  }

  handleUpdate = async () => {
    const currentDate = DateTime.now()
      .setZone('America/Mexico_City')
      .setLocale('es');
    console.log(
      'Update >',
      currentDate.toFormat("EEEE',' dd 'de' MMMM HH':'mm' hrs'")
    );

    // API Request
    const response = await fetch('https://api.clima.xe1e.net/actual');
    const data = await response.json();

    if (!data.exito) {
      console.error(
        'Somewhing went wrong while trying to get the current temperature'
      );

      this.setState({
        current: {
          appStyle:
            currentDate.hour >= 7 && currentDate.hour < 19
              ? { backgroundImage: 'url(/images/background/sunny.webp)' }
              : { backgroundImage: 'url(/images/background/night.webp)' },
        },
        loader: false,
        error: true,
      });
      return;
    }

    const previousTemp =
      this.state.current.temperature.celsius === 0
        ? Math.round(data.actual.temperatura)
        : Math.round(this.state.current.temperature.celsius);
    this.setState({
      current: {
        lastUpdate: currentDate,
        appStyle:
          currentDate.hour >= 7 && currentDate.hour < 19
            ? { backgroundImage: 'url(/images/background/sunny.webp)' }
            : { backgroundImage: 'url(/images/background/night.webp)' },
        temperature: {
          currentUnit: this.state.current.temperature.currentUnit,
          previousTemp,
          celsius: data.actual.temperatura,
          fahrenheit: parseFloat(
            (data.actual.temperatura * (9 / 5) + 32).toFixed(2)
          ),
        },
        humidity: data.actual.humedad,
        pressure: data.actual.presion,
        altitude: data.actual.altitud,
      },
      loader: false,
      error: false,
    });
  };

  handleConvertUnits = () => {
    const current = this.state.current;
    current.temperature.currentUnit =
      this.state.current.temperature.currentUnit === 'celsius'
        ? 'fahrenheit'
        : 'celsius';
    this.setState({ current });
  };

  renderLoader = () => {
    if (this.state.loader) {
      return <Loader />;
    }
  };

  render() {
    return (
      <div className='app' style={this.state.current.appStyle}>
        {this.renderLoader()}
        <div className='fullPage'>
          <CurrentWeather
            currentData={this.state.current}
            error={this.state.error}
            handleConvertUnits={this.handleConvertUnits}
          />
        </div>
        <div className='fullPage'>
          <Charts charts={this.state.charts} />
        </div>
      </div>
    );
  }
}

export default App;
