import React from 'react';
import ReactDOM from 'react-dom';
import Highcharts from 'highcharts/highstock';
import './index.css';
import App from './App';

Highcharts.setOptions({
  lang: {
    loading: 'Cargando...',
    months: [
      'Enero',
      'Febero',
      'Marzo',
      'Abril',
      'Mayo',
      'Junio',
      'Julio',
      'Agosto',
      'Septiembre',
      'Octubre',
      'Noviembre',
      'Diciembre',
    ],
    weekdays: [
      'Domingo',
      'Lunes',
      'Martes',
      'Miércoles',
      'Jueves',
      'Viernes',
      'Sábado',
    ],
    shortMonths: [
      'Ene',
      'Feb',
      'Mar',
      'Abr',
      'May',
      'Jun',
      'Jul',
      'Ago',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ],
    printChart: 'Imprimir gráfica',
    viewFullscreen: 'Ver en pantalla completa',
    exitFullscreen: 'Salir de la pantalla completa',
    rangeSelectorFrom: 'De',
    rangeSelectorTo: 'a',
    rangeSelectorZoom: 'Periodo',
    downloadPNG: 'Descargar como imagen PNG',
    downloadJPEG: 'Descargar como imagen JPEG',
    downloadPDF: 'Descargar como documento PDF',
    downloadSVG: 'Descargar como SVG',
  },
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
