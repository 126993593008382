import React, { Component } from 'react';
import './Loader.css';

class Loader extends Component {
  render() {
    return (
      <div className='loader'>
        <h1>Estación de Clima XE1E</h1>
        <img
          src='/images/loader.gif'
          alt='Cargando página'
          width='142'
          height='158'
        ></img>
      </div>
    );
  }
}

export default Loader;
